





















import { Component, Vue } from 'vue-property-decorator'
import { BAlert, BCard, BTable } from 'bootstrap-vue'
import connectionModule, { HttpStatus, WebSocketStatus } from '@/store/connection'
import { settingsModule } from '@/store/settings'

@Component({
  components: { BTable, BCard, BAlert },
  filters: {
    variantFromStatus(status:WebSocketStatus):string {
      switch (status) {
        case WebSocketStatus.Reconnected:
        case WebSocketStatus.Connected:
          return 'success'
        case WebSocketStatus.Reconnecting:
        case WebSocketStatus.Connecting:
          return 'warning'
        case WebSocketStatus.Disconnected:
        case WebSocketStatus.Error:
          return 'danger'
        default:
          return 'secondary'
      }
    },
    variantFromStatusHttp(status:HttpStatus):string {
      switch (status) {
        case HttpStatus.Error: {
          return 'danger'
        }
        case HttpStatus.Ok: {
          return 'success'
        }
        default:
          return 'secondary'
      }
    }
  }
})
export default class Connections extends Vue {
  get connectionState() {
    return connectionModule.state
  }

  get settings() {
    return settingsModule.state.publicSettings
  }
}
